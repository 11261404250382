import { type } from 'jquery';
import { agreeDisagreeAnswers } from './base-answers';

// const improvementAnswers = [
//   { value: '11', text: 'Portfolio Governance Guidance' },
//   { value: '10', text: 'Ideation and Opportunity Management' },
//   { value: '9', text: 'Strategic Alignment and Prioritization' },
//   { value: '8', text: 'Intake Process' },
//   { value: '7', text: 'Project Pipeline Management' },
//   { value: '6', text: 'Resource Management (Capacity and Demand)' },
//   { value: '5', text: 'Portfolio Performance Tracking' },
//   { value: '4', text: 'Project Performance Tracking' },
//   { value: '3', text: 'Risk Management' },
//   { value: '2', text: 'Data Review and Analysis' },
//   { value: '1', text: 'Portfolio Communications' },
//   { value: '0', text: 'NA' },
// ];

const improvementAnswers = [
  { value: 'governance-guidance', text: 'Portfolio Governance Guidance' },
  { value: 'ideation', text: 'Ideation and Opportunity Management' },
  { value: 'strategic-alignment', text: 'Strategic Alignment and Prioritization' },
  { value: 'intake-process', text: 'Intake Process' },
  { value: 'pipeline-management', text: 'Project Pipeline Management' },
  { value: 'resource-management', text: 'Resource Management (Capacity and Demand)' },
  { value: 'portfolio-performance-tracking', text: 'Portfolio Performance Tracking' },
  { value: 'project-performance-tracking', text: 'Project Performance Tracking' },
  { value: 'risk-management', text: 'Risk Management' },
  { value: 'data-review-and-analysis', text: 'Data Review and Analysis' },
  { value: 'portfolio-communications', text: 'Portfolio Communications' },
  { value: 'na', text: 'NA' },
];

export const cslpmEpmoImprovements = [
  {
    name: 'EPMO Process Improvements',
    key: 'epmoimprovements',
    category: 'epmoimprovements',
    questions: [
      {
        text: 'The EPMO process updates have had a positive impact on my ability to assess portfolio and project performance.',
        key: 'cslpm-positiveimpact-imp',
        type: 'likert',
        answers: agreeDisagreeAnswers,
      },
      {
        text: 'Over the last six months, which of the following EPMO capabilities have improved and helped you do your job better?',
        key: 'cslpm-capabilities-imp',
        type: 'multi-select',
        answers: improvementAnswers,
      },
      {
        text: 'In the coming months, if you could focus on one EPMO capability to improve, which would it be?',
        key: 'cslpm-sentiment-exp',
        type: 'single-select',
        answers: improvementAnswers,
      },
    ],
  },
];
