import { development as developmentHealthCheck, general as generalHealthCheck } from '../squad-health-check';
// import { aceAssessment } from '../ace-assessment';
import accelerateAssessment from '../accelerate';
import sketchCapabilitiesAssessment from '../sketch-capabilities';
import { safeBusinessAgility } from '../safe-business-agility';
import { safeAgileProductDelivery } from '../safe-agile-product-delivery';
import trainAgileAssessment from '../train-agile-assessment';
import { businessAgilityExtended } from '../business-agility-extended';
import questionTypesAssessment from '../question-types';
import { nbmWaysOfWorking } from '../nbm-wow';
import { cslpmBenchmarking } from '../lpm-benchmark';
import { aeciISMaturityModel } from '../aeci-maturity-model';

const baseTemplateProps = {
  is_public: true,
};

const spotifyTemplate = {
  ...baseTemplateProps,
  id: 0,
  key: 'squad-health-check',
  type: 'live',
  configurable: false,
  name: 'Squad Health Check (Software)',
  author: 'Waypointr',
  description: `
Run a quick health check on a software development team to gauge their perception of 11 key aspects of 
how they work together.

Based on the [Spotify Squad Health Check](https://labs.spotify.com/2014/09/16/squad-health-check-model/).
  `,
  assessment: developmentHealthCheck,
  config: null,
  views: {
    orgDetail: ['recommendations', 'squad-scorecard'],
  },
};

const spotifyGeneralTemplate = {
  ...baseTemplateProps,
  id: 3,
  key: 'squad-health-check-general',
  type: 'live',
  configurable: false,
  name: 'Squad Health Check (General)',
  author: 'Waypointr',
  description: `
  Run a quick health check on a team to gauge their perception of 10 key aspects of 
  how they work together.
  
  Based on the [Spotify Squad Health Check](https://labs.spotify.com/2014/09/16/squad-health-check-model/).
    `,
  assessment: generalHealthCheck,
  config: null,
  views: {
    orgDetail: ['recommendations', 'squad-scorecard'],
  },
};

// const aceTemplate = {
//   id: 1,
//   key: 'agile-maturity',
//   type: 'survey',
//   configurable: false,
//   name: 'Agile Maturity Assessment',
//   author: 'ACE',
//   description: 'A basic assessment',
//   assessment: aceAssessment,
//   config: null
// };

const accelerateTemplate = {
  ...baseTemplateProps,
  id: 2,
  key: 'devops-capabilities',
  type: 'survey',
  configurable: true,
  name: 'DevOps Capabilities Assessment',
  author: 'Waypointr',
  description: `
A deep dive into 24 capabilities that have been shown to correlate with highly 
performing organizations. 

Based on the book [Accelerate](https://itrevolution.com/book/accelerate/) 
by Nicole Forsgren, Jez Humble and Gene Kim
  `,
  assessment: accelerateAssessment,
  config: {
    version: '0.1',
    categories: {
      process: { display_name: 'Process', ordinal: 1 },
      culture: { display_name: 'Culture', ordinal: 2 },
      metrics: { display_name: 'Metrics', ordinal: 3 },
      technical: { display_name: 'Technical', ordinal: 4 },
      architecture: { display_name: 'Architecture', ordinal: 5 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const sketchCapabilitiesTemplate = {
  ...baseTemplateProps,
  id: 4,
  key: sketchCapabilitiesAssessment.key,
  type: 'survey',
  configurable: true,
  name: 'Sketch Capabilities Assessment',
  author: 'Sketch',
  description: `
A high-level assessment of more than 30 distinct organizational capabilities that 
have been found to drive successful outcomes.

Partially based on the book [Accelerate](https://itrevolution.com/book/accelerate/) 
by Nicole Forsgren, Jez Humble and Gene Kim
    `,
  assessment: sketchCapabilitiesAssessment,
  config: {
    version: '0.1',
    categories: {
      culture: { display_name: 'Culture', ordinal: 1 },
      'org-structure': { display_name: 'Org Structure', ordinal: 2 },
      planning: { display_name: 'Planning', ordinal: 3 },
      process: { display_name: 'Process', ordinal: 4 },
      product: { display_name: 'Product', ordinal: 5 },
      technical: { display_name: 'Technical', ordinal: 6 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const safeBusinessAgilityTemplate = {
  ...baseTemplateProps,
  id: 5,
  key: safeBusinessAgility.key,
  type: 'survey',
  configurable: true,
  name: safeBusinessAgility.name,
  author: 'Scaled Agile',
  description: `
Measure the Agile maturity of your enterprise based on core tenets of the [Scaled Agile framework](https://scaledagileframework.com).
    `,
  assessment: safeBusinessAgility,
  config: {
    version: '0.1',
    categories: {
      technical: { display_name: 'Team & Technical', ordinal: 1 },
      'product-delivery': { display_name: 'Product Delivery', ordinal: 2 },
      'solution-delivery': { display_name: 'Solution Delivery', ordinal: 3 },
      'portfolio-management': { display_name: 'Portfolio Management', ordinal: 4 },
      leadership: { display_name: 'Leadership', ordinal: 5 },
      organization: { display_name: 'Organizational', ordinal: 6 },
      learning: { display_name: 'Learning Culture', ordinal: 7 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const safeAgileProductDeliveryTemplate = {
  ...baseTemplateProps,
  id: 7,
  key: safeAgileProductDelivery.key,
  type: 'survey',
  configurable: true,
  name: safeAgileProductDelivery.name,
  author: 'Scaled Agile',
  description: `
Measure the maturity of your product delivery practices, including 
_Customer Centricity and Design Thinking_ and your ability to 
_Develop on Cadence; Release on Demand_.

Based on core tenets of the [Scaled Agile framework](https://scaledagileframework.com).
    `,
  assessment: safeAgileProductDelivery,
  config: {
    version: '0.1',
    categories: {
      'customer-centricity': { display_name: 'Customer Centricity and Design Thinking', ordinal: 1 },
      'develop-on-cadence': { display_name: 'Develop on Cadence; Release on Demand', ordinal: 2 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const trainAgileTemplate = {
  ...baseTemplateProps,
  id: 6,
  key: trainAgileAssessment.key,
  type: 'survey',
  configurable: true,
  name: trainAgileAssessment.name,
  author: 'Centene',
  is_public: false,
  description: `
  This assessment is designed to evaluate how far you have progressed on your Agile journey. 
    `,
  assessment: trainAgileAssessment,
  config: {
    version: '0.1',
    categories: {
      'team-dynamics': { display_name: 'Team Dynamics', ordinal: 1 },
      'product-management-and-development': { display_name: 'Product Management & Development', ordinal: 2 },
      'agile-process-mechanics': { display_name: 'Agile Process Mechanics', ordinal: 3 },
      'agile-engineering-practices': { display_name: 'Engineering Practices', ordinal: 4 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const businessAgilityExtendedTemplate = {
  ...baseTemplateProps,
  id: 8,
  key: businessAgilityExtended.key,
  type: 'survey',
  configurable: true,
  name: businessAgilityExtended.name,
  author: 'BJC',
  is_public: false,
  description: `
  Measure the Agile maturity of your enterprise along with an inspection of 
  your organization's readiness to execute on an Operation Model Transformation (OMT).
  `,
  assessment: businessAgilityExtended,
  config: {
    version: 0.1,
    categories: {
      'business-agility': { display_name: 'Business Agility', ordinal: 1 },
      'change-readiness': { display_name: 'Change Readiness', ordinal: 2 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const questionTypesTemplate = {
  ...baseTemplateProps,
  id: -1,
  key: questionTypesAssessment.key,
  type: 'survey',
  configurable: true,
  name: questionTypesAssessment.name,
  author: 'Sketch',
  is_public: false,
  description: `
  We don't need no stinkin' description!
  `,
  assessment: questionTypesAssessment,
  config: {
    version: 0.1,
    categories: {
      mixed: { display_name: 'Mixed', ordinal: 1 },
      likert: { display_name: 'Likert', ordinal: 1 },
      'unscored-likert': { display_name: 'Unscored Likert', ordinal: 1 },
      'long-text': { display_name: 'Long Text', ordinal: 1 },
      'multi-select': { display_name: 'Multi Select', ordinal: 1 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const nbmWaysOfWorkingTemplate = {
  ...baseTemplateProps,
  id: 9,
  key: nbmWaysOfWorking.key,
  type: 'survey',
  configurable: true,
  name: nbmWaysOfWorking.name,
  author: 'Nestle Purina',
  is_public: false,
  description: `Measure how well your team follows our standard Ways of Working.`,
  assessment: nbmWaysOfWorking,
  config: {
    version: 0.1,
    categories: {
      planning: { display_name: 'Planning', ordinal: 1 },
      'scope-management': { display_name: 'Scope Management', ordinal: 2 },
      estimating: { display_name: 'Estimating', ordinal: 3 },
      'sprint-practices': { display_name: 'Daily Sprint Practices', ordinal: 4 },
      'metrics-measures': { display_name: 'Metrics, Measures & Reviews', ordinal: 5 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const cslpmTemplate = {
  ...baseTemplateProps,
  id: 10,
  key: 'cslpm-epmo-bm',
  type: 'survey',
  configurable: true,
  name: 'CareSource EPMO LPM Benchmark',
  author: 'Sketch',
  is_public: false,
  description: `
The purpose of this survey is to collect observations and stories to drive continuous improvement efforts for CareSource's EPMO LPM processes and services. 

Prepared for CareSource.
  `,
  assessment: cslpmBenchmarking,
  config: {
    version: '0.1',
    categories: {
      demographics: { display_name: 'Demographics', ordinal: 1 },
      epmoeffect: { display_name: 'Effect of EPMO', ordinal: 2 },
      epmoimprovements: { display_name: 'EPMO Process Improvements', ordinal: 3 },
      epmoexperience: { display_name: 'Experience with EPMO', ordinal: 4 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const aeciMaturityModelTemplate = {
  ...baseTemplateProps,
  id: 11,
  key: aeciISMaturityModel.key,
  type: 'survey',
  configurable: true,
  name: aeciISMaturityModel.name,
  author: 'AECI',
  is_public: false,
  description: `
Evaluate your team's agile maturity based on the AECI IS Maturity Model.
  `,
  assessment: aeciISMaturityModel,
  config: {
    version: '0.1',
    categories: {
      'agile-ceremonies': { display_name: 'Agile Ceremonies', ordinal: 1 },
      ownership: { display_name: 'Ownership', ordinal: 2 },
      'work-management': { display_name: 'Work Management', ordinal: 3 },
      collaboration: { display_name: 'Collaboration', ordinal: 4 },
      tooling: { display_name: 'Tooling', ordinal: 5 },
    },
  },
  views: {
    orgDetail: ['recommendations'],
  },
};

const assessmentTemplates = [
  spotifyTemplate,
  spotifyGeneralTemplate,
  nbmWaysOfWorkingTemplate,
  sketchCapabilitiesTemplate,
  accelerateTemplate,
  safeBusinessAgilityTemplate,
  safeAgileProductDeliveryTemplate,
  trainAgileTemplate,
  businessAgilityExtendedTemplate,
  questionTypesTemplate,
  cslpmTemplate,
  aeciMaturityModelTemplate,
];
export default assessmentTemplates;
