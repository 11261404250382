import { cslpmDemographics } from './question-sets/cslpm-demographics';
import { cslpmEpmoImpact } from './question-sets/cslpm-epmoimpact';
import { cslpmEpmoExperiences } from './question-sets/cslpm-epmoexperiences';
import { cslpmEpmoImprovements } from './question-sets/cslpm-improvements';

export const cslpmBenchmarking = {
  name: 'CareSource EPMO LPM Benchmark',
  key: 'cslpm-epmo-bm',
  question_sets: [...cslpmDemographics, ...cslpmEpmoImpact, ...cslpmEpmoImprovements, ...cslpmEpmoExperiences],
};

export default cslpmBenchmarking;
