import React, { useMemo } from 'react';
import classnames from 'classnames';
import { keyBy, map, max } from 'lodash';

import { FIELD_DELIMITER } from 'js/utils/string';

import { AxisRight } from '@visx/axis';
import { Grid } from '@visx/grid';
import { scaleBand, scaleLinear } from '@visx/scale';

import standardCohortKeys from 'js/utils/cohort-keys';

import styles from './QuestionResults.module.scss';
// import { Link, useRouteMatch } from 'react-router-dom';

function addResponseToCohortAccumulator(acc, cohort, responseValue) {
  const responses =
    responseValue === null ? [] : responseValue.split(FIELD_DELIMITER);
  acc[cohort] = acc[cohort] || {};
  responses.forEach((r) => {
    acc[cohort][r] = (acc[cohort][r] ?? 0) + 1;
  });

  return acc;
}

function MultiSelectResults({
  question,
  filter,
  participantCohortMap = {},
  cohorts,
  activeCohort: activeCohortContext,
  tag: Tag = 'article',
  className,
  width = 400,
  height = 125,
  margin = {
    top: 7,
    bottom: 5,
    left: 0,
    right: 0,
  },
  primaryResultsOverlayText,
  ...props
}) {
  // const {
  //   params: { assessmentId, assessmentSlug, teamId, workspaceId },
  // } = useRouteMatch(
  //   '/w/:workspaceId/teams/:teamId/assessments/:assessmentSlug/detail/:assessmentId'
  // );

  const xMax = width - margin.right - margin.left;
  const yMax = height - margin.bottom - margin.top;
  const cohortColorIndices = {
    ...keyBy(
      cohorts?.map((c, i) => ({ ...c, index: i + 2 })) ?? [],
      (c) => c.value ?? standardCohortKeys.NULL
    ),
    [standardCohortKeys.CUMULATIVE]: { index: 1 },
  };

  const cohortKeys = useMemo(
    () => Object.keys(filter?.cohorts ?? {}),
    [filter?.cohorts]
  );

  const activeCohort = activeCohortContext ?? standardCohortKeys.CUMULATIVE;

  const applyCohortFilter = cohortKeys.length > 0;
  const resultsByCohort = useMemo(() => {
    return question.responses.reduce((acc, r) => {
      const cohort = participantCohortMap[r.participant_id];
      if (applyCohortFilter && cohortKeys.indexOf(cohort) === -1) {
        return acc;
      }
      const responseValue = r.is_na ? null : r.value;

      acc = addResponseToCohortAccumulator(
        acc,
        standardCohortKeys.CUMULATIVE,
        responseValue
      );
      acc = addResponseToCohortAccumulator(
        acc,
        cohort ?? standardCohortKeys.NULL,
        responseValue
      );

      return acc;
    }, {});
  }, [question, participantCohortMap, applyCohortFilter, cohortKeys]);

  const answers = useMemo(
    () => question.answers.filter((a) => !a.is_na),
    [question.answers]
  );

  // TODO: actually use the cohort data
  const data = resultsByCohort[standardCohortKeys.CUMULATIVE] ?? {};

  const { x, y, xScale, yScale, numTicksY, naResponseCount, barWidth, rx } =
    useMemo(() => {
      const cumulativeResponses =
        resultsByCohort[standardCohortKeys.CUMULATIVE] ?? {};
      const naResponseCount = cumulativeResponses[null] || 0;

      const maxResponses =
        max(map(cumulativeResponses, (x, key) => (key === null ? 0 : x))) || 1;

      const numTicksY = Math.min(4, Math.max(1, maxResponses));

      const xScale = scaleBand({
        range: [0, xMax],
        domain: answers.map(({ value }) => value),
        padding: 0,
      });

      const yScale = scaleLinear({
        range: [yMax, 0],
        domain: [
          0,
          Object.keys(cumulativeResponses).length > 0
            ? Math.max(1.15, 1.15 * maxResponses)
            : 1.15,
        ],
      });

      const barWidth = Math.min(15, 0.6 * xScale.bandwidth());
      const rx = Math.min(2, 0.2 * barWidth);

      const x = (d) => xScale(d) + xScale.bandwidth() / 2;
      const y = yScale;

      return {
        x,
        y,
        xScale,
        yScale,
        numTicksY,
        naResponseCount,
        barWidth,
        rx,
      };
    }, [answers, resultsByCohort, xMax, yMax]);

  const applyOverlay = !resultsByCohort[standardCohortKeys.CUMULATIVE];
  const rotateAnswers = answers.length > 7;

  return (
    <Tag className={classnames(className, 'question-results')} {...props}>
      <div
        className={classnames('question-results-wrapper', {
          'no-answers-overlay': applyOverlay,
        })}
      >
        <div className="question-text">
          <p>{question.text}</p>
        </div>
        {naResponseCount !== 0 && (
          <div className="position-relative mb-4">
            <div className={styles.naBadge}>
              <span className={styles.naCount}>NA: {naResponseCount}</span>
            </div>
          </div>
        )}
        <div className="primary-results-wrapper">
          <svg viewBox={`0 0 ${width} ${height}`} className="primary-results">
            <g transform={`translate(${margin.left}, ${margin.top})`}>
              <Grid
                top={0}
                left={0}
                xScale={xScale}
                yScale={yScale}
                numTicksRows={numTicksY}
                width={xMax}
                height={yMax}
                strokeDasharray="2,2"
                stroke="rgba(200,200,200,1)"
                xOffset={xScale.bandwidth() / 2}
              />
              <g
                className={`palette-chart-${cohortColorIndices[activeCohort].index}`}
              >
                {answers.map(({ value }, i) => {
                  const count = data[value] ?? 0;
                  const h = Math.max(0, Math.min(rx, (yMax - y(count)) / 2));

                  return (
                    <g
                      transform={`translate(${x(value) - barWidth / 2},0)`}
                      key={value}
                    >
                      <rect
                        x={0}
                        y={yMax - h}
                        width={barWidth}
                        height={h}
                        className="palette-fill"
                      />
                      <rect
                        x={0}
                        y={y(count)}
                        width={barWidth}
                        height={yMax - y(count)}
                        className="palette-fill"
                        rx={rx}
                      />
                    </g>
                  );
                })}
              </g>
              <AxisRight
                top={0}
                right={xMax}
                scale={yScale}
                numTicks={numTicksY}
                tickFormat={yScale.tickFormat(numTicksY, '.0f')}
                hideZero
                axisClassName="axis-y"
                axisLineClassName="axis-y-line"
                tickClassName="axis-y-tick"
                tickLabelProps={() => ({
                  dx: '-.5em',
                  dy: '-.5em',
                  className: 'axis-y-label',
                })}
              />
            </g>
          </svg>
          {applyOverlay && (
            <div className="no-response-overlay">
              <div>No responses</div>
            </div>
          )}
          {primaryResultsOverlayText !== undefined &&
            primaryResultsOverlayText !== null && (
              <div className="primary-results-overlay">
                {primaryResultsOverlayText}
              </div>
            )}
        </div>
        <ol
          className="answers"
          style={{ gridTemplateColumns: `repeat(${answers.length}, 1fr)` }}
        >
          {answers.map((a) => {
            return (
              <li className="answer" key={`answer-label-${a.id}`}>
                <div
                  title={a.text}
                  className={classnames('answer-text', {
                    rotate: rotateAnswers,
                  })}
                >
                  {a.text}
                </div>
              </li>
            );
          })}
        </ol>
        {/* <div className="text-right">
          <Link
            to={`/w/${workspaceId}/teams/${teamId}/assessments/${assessmentSlug}/detail/${assessmentId}/questions/${question.id}`}
            className="info"
          >
            &gt; View detailed results
          </Link>
        </div> */}
      </div>
    </Tag>
  );
}

export default MultiSelectResults;
